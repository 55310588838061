import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import './index.scss'

const IndexPage = () => (
  <Layout index='true'>
    <SEO title="Home" />
    <div className='home'>
      <h1>Welcome</h1>
      <p>Hi, I'm Akane. I am a web developer.</p>
    </div>
  </Layout>
)

export default IndexPage
